'use client';
/**
 * 安裝google廣告
 * Google Publisher Tag: https://developers.google.com/publisher-tag/guides/get-started?hl=zh-tw
 * window底下增加googletag物件
 */

export const Initialize = () => {
  return <script async src='https://securepubads.g.doubleclick.net/tag/js/gpt.js' />;
};

export default Initialize;
