// https://cnyesrd.atlassian.net/wiki/spaces/PS/pages/2143387649/Mweb
import LinkFactory from '@fe-common-utils/libs/link';
import { NewsCategory } from '@fe-news/constants/categories';

const { newsChannelLink, wwwChannelLink } = LinkFactory;

const navigation = {
  [NewsCategory.HEADLINE]: {
    name: '即時頭條',
    title: '頭條新聞',
    keywords: ['新聞', '頭條'],
    description:
      '提供最即時的頭條新聞，包含國內外最新時事、外匯變動、股市波動變化等頭條新聞，鉅亨網帶您了解市場重要新聞。',
    category: NewsCategory.HEADLINE,
    navs: [
      { name: '全部', title: '頭條新聞', category: NewsCategory.HEADLINE },
      {
        name: '主編精選',
        title: '主編精選',
        category: NewsCategory.NEWS_24H,
        description:
          '提供今日要聞，快速掌握今日國內外重要新聞、國際市場走勢、重大財經訊息，前往Anue鉅亨了解今日重點新聞。'
      },
      { name: '議題', title: '議題', category: NewsCategory.TOP_TOPICS },
      { name: '宏觀', title: '宏觀', category: NewsCategory.NEWS_MARCO },
      { name: '人氣', title: '人氣', category: NewsCategory.TRENDING, link: `${newsChannelLink}/trending` },
      { name: '專題', title: '專題', category: NewsCategory.PROJECT, link: `${newsChannelLink}/projects/cat/all` },
      { name: '總覽', title: '總覽', category: NewsCategory.ALL }
    ],
    link: '',
    isRoot: true,
    enabled: true
  },
  // [NewsCategory.HEADLINE_ALL]: {
  //   name: '即時頭條',
  //   title: '頭條新聞',
  //   keywords: ['新聞', '頭條'],
  //   description:
  //     '提供最即時的頭條新聞，包含國內外最新時事、外匯變動、股市波動變化等頭條新聞，鉅亨網帶您了解市場重要新聞。',
  //   category: NewsCategory.HEADLINE,
  //   navs: [
  //     { name: '全部', title: '頭條新聞', category: NewsCategory.HEADLINE },
  //     {
  //       name: '主編精選',
  //       title: '主編精選',
  //       category: NewsCategory.NEWS_24H,
  //       description:
  //         '提供今日要聞，快速掌握今日國內外重要新聞、國際市場走勢、重大財經訊息，前往Anue鉅亨了解今日重點新聞。'
  //     },
  //     { name: '議題', title: '議題', category: NewsCategory.TOP_TOPICS },
  //     { name: '宏觀', title: '宏觀', category: NewsCategory.NEWS_MARCO },
  //     { name: '人氣', title: '人氣', category: NewsCategory.TRENDING, link: `${newsChannelLink}/trending` },
  //     { name: '專題', title: '專題', category: NewsCategory.PROJECT, link: `${newsChannelLink}/projects/cat/all` },
  //     { name: '總覽', title: '總覽', category: NewsCategory.ALL }
  //   ],
  //   link: '',
  //   isRoot: true,
  //   enabled: true
  // },
  [NewsCategory.TW_STOCK]: {
    name: '台股',
    title: '股市新聞',
    keywords: ['台股', '股市', '新聞'],
    description:
      '最新台灣股市新聞，包含上市櫃公司最新訊息、股市波動、政治經濟等股市新聞及市場評論分析，迅速了解股市趨勢。',
    category: NewsCategory.TW_STOCK,
    navs: [
      { name: '全部', title: '股市新聞', category: NewsCategory.TW_STOCK },
      { name: '新聞', title: '台股新聞', category: NewsCategory.TW_STOCK_NEWS },
      { name: '台灣政經', title: '台灣政經', category: NewsCategory.TW_MACRO },
      { name: '台股盤勢', title: '台股盤勢', category: NewsCategory.TW_QUO },
      { name: '專家觀點', title: '專家觀點', category: NewsCategory.STOCK_REPORT },
      { name: '工具', title: '投資工具', category: NewsCategory.STOCK_TUTORIAL },
      { name: '台股公告', title: '台股公告', category: NewsCategory.TW_BULL },
      { name: '興櫃', title: '興櫃公告', category: NewsCategory.EME_BULL },
      { name: '表格', title: '台股表格', category: NewsCategory.TW_CALC },
      { name: 'ETF', title: 'ETF', category: NewsCategory.ETF }
    ],
    link: '',
    isRoot: true,
    enabled: true
  },
  [NewsCategory.WD_STOCK]: {
    name: '美股',
    title: '美股股市新聞',
    keywords: ['國際', '新聞', 'NASDAQ', '美元'],
    description:
      '提供國際新聞，包含國際情勢變化、NASDAQ 及國際股市波動、美元及外幣匯率等國際新聞及市場分析，迅速了解全球趨勢。',
    category: NewsCategory.WD_STOCK,
    navs: [
      { name: '頭條', title: '美股股市頭條新聞', category: NewsCategory.WD_STOCK },
      { name: '全部', title: '美股股市新聞', category: NewsCategory.WD_STOCK_ALL },
      { name: '美股雷達', title: '美股雷達', category: NewsCategory.US_STOCK },
      { name: '國際政經', title: '國際政經', category: NewsCategory.WD_MACRO },
      { name: '歐亞股', title: '歐亞股', category: NewsCategory.EU_ASIA_STOCK },
      { name: '美股速報', title: '美股速報', category: NewsCategory.US_LIVE_FORECATE }
    ],
    link: '',
    isRoot: true,
    enabled: true
  },
  [NewsCategory.TECH]: {
    name: '科技',
    title: '科技新聞',
    keywords: ['科技', '新聞'],
    description:
      '提供最新、熱門的科技相關新聞，包括全球供應鏈市場、科技應用及重大科技趨勢分析觀點等，幫助投資人掌握科技產業最新動態。',
    category: NewsCategory.TECH,
    navs: [{ name: '全部', title: '科技新聞', category: NewsCategory.TECH }],
    link: '',
    isRoot: true,
    enabled: true
  },
  [NewsCategory.CN_STOCK]: {
    name: '陸港股',
    title: '陸港股新聞',
    keywords: ['A股', '港股', '新聞', '滬深股', '上證'],
    description: '最新大陸股市新聞，包含A股港股最新訊息、大陸政治經濟情報、及市場評論分析。',
    category: NewsCategory.CN_STOCK,
    navs: [
      { name: '頭條', title: '陸港股頭條新聞', category: NewsCategory.CN_STOCK },
      { name: '全部', title: '陸港股新聞', category: NewsCategory.CN_STOCK_ALL },
      { name: '港股', title: '港股', category: NewsCategory.HK_STOCK },
      { name: 'A股', title: 'A股', category: NewsCategory.SH_STOCK },
      { name: '大行報告', title: '大行報告', category: NewsCategory.HK_REPORT },
      { name: '新股上市', title: '新股上市', category: NewsCategory.HK_IPO },
      { name: '權證', title: '權證', category: NewsCategory.HK_WARRANT },
      { name: '香港政經', title: '香港政經', category: NewsCategory.HK_MACRO },
      { name: '大陸政經', title: '大陸政經', category: NewsCategory.CN_MACRO }
    ],
    link: '',
    isRoot: true,
    enabled: true
  },
  [NewsCategory.POP_TOPIC]: {
    name: '熱門時事',
    title: '新聞-熱門時事',
    keywords: ['熱門時事', '熱門議題', '時事', '議題', '新聞', '頭條'],
    description:
      '查看最新具有話題的熱門時事，提供熱門時事相關資訊、介紹、以及歸納與時事相關的新聞，並帶出相關連的商品，快速掌握時事脈絡。',
    category: NewsCategory.POP_TOPIC,
    navs: [],
    link: '/poptopic',
    isRoot: true,
    enabled: true
  },
  [NewsCategory.ANUE_LIVE]: {
    name: '速報',
    title: '速報',
    keywords: ['速報'],
    description: '',
    category: NewsCategory.ANUE_LIVE,
    navs: [
      { name: '全部', title: '速報', category: NewsCategory.ANUE_LIVE },
      { name: '台股盤前', shortName: '台盤前', title: '台股盤前', category: NewsCategory.TW_PRE_MARKET },
      { name: '台股盤中', shortName: '台盤中', title: '台股盤中', category: NewsCategory.TW_LIVE },
      { name: '台股盤後', shortName: '台盤後', title: '台股盤後', category: NewsCategory.TW_AFTER_HOURS },
      { name: '台股預估', shortName: '台預估', title: '台股預估', category: NewsCategory.TW_FORECAST },
      { name: '美股盤中', shortName: '美盤中', title: '美股盤中', category: NewsCategory.US_LIVE },
      { name: '美股預估', shortName: '美預估', title: '美股預估', category: NewsCategory.US_FORECAST },
      { name: '虛幣', title: '虛擬貨幣', category: NewsCategory.CRYPTO_LIVE },
      { name: '外匯', title: '外匯盤中', category: NewsCategory.FOREX_LIVE },
      { name: '營收', title: '營收速報', category: NewsCategory.TW_REVENUE }
    ],
    link: '',
    isRoot: true,
    enabled: true
  },
  [NewsCategory.FUND]: {
    name: '基金',
    title: '基金',
    keywords: ['基金', '新聞'],
    description:
      '提供包含最新淨值、績效走勢、基金公司、基金組別、投資地區等重要資訊，以及前瞻的研究報告與中立客觀的投資建議，協助您找到想要的基金。',
    category: NewsCategory.FUND,
    navs: [{ name: '全部', title: '基金', category: NewsCategory.FUND }],
    link: '',
    isRoot: true,
    enabled: true
  },
  [NewsCategory.FOREX]: {
    name: '外匯',
    title: '外幣匯率新聞',
    keywords: ['外幣', '匯率', '美元', '日圓', '新聞'],
    description:
      '提供匯率相關新聞，包含外幣匯率走勢新聞、美元、日幣及其他外幣指數、國際匯率情勢分析，帶您迅速了解全球貨幣趨勢。',
    category: NewsCategory.FOREX,
    navs: [{ name: '全部', title: '外匯', category: NewsCategory.FOREX }],
    link: '',
    isRoot: true,
    enabled: true
  },
  [NewsCategory.FUTURES]: {
    name: '期貨',
    title: '期貨新聞',
    keywords: ['黃金', '期貨', '能源', '新聞'],
    description: '提供期貨相關新聞，包含能源走勢、黃金及原物料訊息、國際期貨新聞，迅速了解期貨相關資訊。',
    category: NewsCategory.FUTURES,
    navs: [
      { name: '全部', title: '期貨新聞', category: NewsCategory.FUTURES },
      { name: '指數', title: '指數', category: NewsCategory.FUTURES_INDEX },
      { name: '股票', title: '股票', category: NewsCategory.FUTURES_STOCK },
      { name: '能源', title: '能源', category: NewsCategory.FUTURES_ENERGY },
      { name: '債券', title: '債券', category: NewsCategory.FUTURES_BOND },
      { name: '農作', title: '農作', category: NewsCategory.FUTURES_PRODUCE },
      { name: '黃金', title: '黃金', category: NewsCategory.FUTURES_METALS }
    ],
    link: '',
    isRoot: true,
    enabled: true
  },
  [NewsCategory.CRYPTO]: {
    name: '區塊鏈',
    title: '區塊鏈新聞',
    keywords: ['區塊鏈', '虛擬貨幣', '加密貨幣', '新聞'],
    description: '提供重要的區塊鏈趨勢新聞，包含區塊鏈技術、區塊鏈應用、虛擬貨幣、加密貨幣，帶你了解區塊鏈最新發展。',
    category: NewsCategory.CRYPTO,
    navs: [
      { name: '全部', title: '區塊鏈新聞', category: NewsCategory.CRYPTO },
      { name: '幣圈', title: '幣圈', category: NewsCategory.BC_CRYPTO },
      { name: '鏈文', title: '鏈文', category: NewsCategory.BC_NEWS },
      { name: '新手村', title: '新手村', category: NewsCategory.BC_TUTORIAL },
      { name: '快訊', title: '快訊', category: NewsCategory.BC_LIVE }
    ],
    link: '',
    isRoot: true,
    enabled: true
  },
  [NewsCategory.HOUSE]: {
    name: '房產',
    title: '房產新聞',
    keywords: ['房產', '海外', '新聞', '日本', '馬來西亞', '柬埔寨', '泰國'],
    description:
      '台灣房產、海外房產相關新聞情報，包含日本、馬來西亞、柬埔寨、泰國、澳洲、美國海外等地海外房產投資相關新聞。',
    category: NewsCategory.HOUSE,
    navs: [
      { name: '全部', title: '房產新聞', category: NewsCategory.HOUSE },
      { name: '大陸房市', title: '大陸房市', category: NewsCategory.HOUSE_CN },
      { name: '香港房市', title: '香港房市', category: NewsCategory.HOUSE_HK },
      { name: '台灣房市', title: '台灣房市', category: NewsCategory.HOUSE_TW },
      { name: '海外房市', title: '海外房市', category: NewsCategory.HOUSE_WD }
    ],
    link: '',
    isRoot: true,
    enabled: true
  },
  [NewsCategory.TW_MONEY]: {
    name: '理財',
    title: '理財新聞',
    keywords: ['理財', '新聞'],
    description: '最新理財相關新聞，你不可不知的基金、消費、保險新聞資訊，幫助你聰明理財。',
    category: NewsCategory.TW_MONEY,
    navs: [
      { name: '全部', title: '理財新聞', category: NewsCategory.TW_MONEY },
      { name: '銀行', title: '銀行', category: NewsCategory.BANK },
      { name: '保險', title: '保險', category: NewsCategory.TW_INSURANCE },
      { name: '消費', title: '消費', category: NewsCategory.SPENDING },
      { name: '其他', title: '其他', category: NewsCategory.FINANCIAL }
    ],
    link: '',
    isRoot: true,
    enabled: true
  },
  [NewsCategory.CELEBRITY_AREA]: {
    name: '新視界',
    title: '新視界',
    keywords: [],
    description:
      '最新財經專家評論分析，針對全球市場趨勢、基金表現及股票投資等提出評論見解，集合不同財經專家精闢解說，讓您從多元角度，快速了解財經訊息趨勢，掌握全球市場。',
    category: NewsCategory.CELEBRITY_AREA,
    navs: [
      { name: '文章專區', title: '文章專區', category: NewsCategory.CELEBRITY_AREA },
      { name: '名家總覽', title: '名家總覽', category: NewsCategory.COLUMNISTS, link: `/columnists` },
      { name: '走進中國', title: '走進中國', category: NewsCategory.COLUMNIST, link: `/columnists/45` },
      { name: '野村精選', title: '野村精選', category: NewsCategory.COLUMNIST, link: `/columnists/47` },
      { name: '投資美國', title: '投資美國', category: NewsCategory.COLUMNIST, link: `/columnists/51` },
      { name: '家族傳承', title: '家族傳承', category: NewsCategory.COLUMNIST, link: `/columnists/53` },
      {
        name: '投顧名師',
        title: '投顧名師',
        category: NewsCategory.COLUMNIST,
        link: 'https://campaign.cnyes.com/topics/analyst/'
      }
    ],
    link: '',
    isRoot: false,
    enabled: true
  },
  [NewsCategory.TOPICS]: {
    name: '專題',
    title: '鉅亨專題',
    keywords: ['專題', '報導', '投資', '趨勢'],
    description: '鉅亨網專題報導，帶你洞悉最新趨勢，不同專題帶你瞭解如何跟著趨勢選擇投資標地。',
    category: NewsCategory.TOPICS,
    navs: [],
    link: `${newsChannelLink}/projects`,
    isRoot: true,
    enabled: true
  },
  [NewsCategory.MAG]: {
    name: '雜誌',
    title: '雜誌',
    keywords: [],
    description: '',
    category: NewsCategory.MAG,
    navs: [],
    link: `${newsChannelLink}/news/cat/mag`,
    isRoot: true,
    enabled: true
  },
  [NewsCategory.MY_TRACKING]: {
    name: '我的追蹤',
    title: '我的追蹤',
    keywords: [],
    description: '',
    category: NewsCategory.MY_TRACKING,
    navs: [],
    link: `${wwwChannelLink}/member/mytag/all`,
    isRoot: false,
    enabled: false
  },
  [NewsCategory.MY_NEWS]: {
    name: '我的新聞',
    title: '我的新聞',
    category: NewsCategory.MY_NEWS,
    navs: [
      { name: '我的追蹤', title: '我的追蹤', category: NewsCategory.MY_TRACK, link: '/member/mytag/all' },
      { name: '我的收藏', title: '我的收藏', category: NewsCategory.NEWS_COLLECT, link: '/member/news_collect' },
      { name: '我的留言', title: '我的留言', category: NewsCategory.MY_COMMENT, link: '/member/my_comment' },
      { name: '熱門排行', title: '熱門排行', category: NewsCategory.RANKING_LIST, link: '/member/rankinglist' }
    ],
    link: '/member/mytag/all',
    isRoot: true,
    enabled: true
  },
  [NewsCategory.MY_TRACK]: {
    name: '我的追蹤',
    title: '我的追蹤',
    category: NewsCategory.MY_TRACK,
    navs: [
      { name: '追蹤的標籤', title: '追蹤的標籤', category: NewsCategory.MY_TAG, link: '/member/mytag/all' },
      { name: '自選股新聞', title: '自選股新聞', category: NewsCategory.PORTFOLIOS, link: '/member/portfolios' },
      { name: '追蹤的時事', title: '追蹤的時事', category: NewsCategory.MY_POP_TOPIC, link: '/member/mypoptopic' }
    ],
    link: '/member/mytag/all',
    isRoot: false,
    enabled: false
  },
  [NewsCategory.MY_TAG]: {
    name: '追蹤的標籤',
    title: '我的新聞-追蹤標籤',
    keywords: ['我的新聞', '個人化新聞', '我的追蹤', '我的收藏', '新聞標籤', '熱門時事'],
    description:
      '「我的新聞」根據您追蹤的標籤、熱門時事與自選商品，提供最符合您需求的新聞內容，即時掌握專屬於您的新聞動態資訊。',
    category: NewsCategory.MY_TAG,
    link: '/member/mytag/all',
    navs: [],
    isRoot: false,
    enabled: false
  },
  [NewsCategory.PORTFOLIOS]: {
    name: '自選股新聞',
    title: '我的新聞-自選清單',
    keywords: ['我的新聞', '個人化新聞', '我的追蹤', '我的收藏', '新聞標籤', '熱門時事', '自選股'],
    description:
      '「我的新聞」根據您追蹤的標籤、熱門時事與自選商品，提供最符合您需求的新聞內容，即時掌握專屬於您的新聞動態資訊。',
    category: NewsCategory.PORTFOLIOS,
    link: '/member/portfolios',
    navs: [],
    isRoot: false,
    enabled: false
  },
  [NewsCategory.MY_POP_TOPIC]: {
    name: '追蹤的時事',
    title: '我的新聞-追蹤時事',
    keywords: ['我的新聞', '個人化新聞', '我的追蹤', '我的收藏', '新聞標籤', '熱門時事'],
    description:
      '「我的新聞」根據您追蹤的標籤、熱門時事與自選商品，提供最符合您需求的新聞內容，即時掌握專屬於您的新聞動態資訊。',
    category: NewsCategory.MY_POP_TOPIC,
    link: '/member/mypoptopic',
    navs: [],
    isRoot: false,
    enabled: false
  },
  [NewsCategory.NEWS_COLLECT]: {
    name: '我的收藏',
    title: '我的新聞-我的收藏',
    keywords: ['我的新聞', '個人化新聞', '我的追蹤', '新聞', '頭條', '收藏'],
    description: '「我的新聞」提供您查看並管理您收藏的新聞文章，快速找到過去感興趣的內容，隨時掌握重要資訊。',
    category: NewsCategory.NEWS_COLLECT,
    link: '/member/news_collect',
    navs: [],
    isRoot: false,
    enabled: false
  },
  [NewsCategory.RANKING_LIST]: {
    name: '熱門排行',
    title: '我的新聞-熱門排行',
    keywords: ['熱門', '排行榜', '熱門追蹤', '熱門收藏', '熱門標籤', '熱門時事'],
    description: '「我的新聞」提供您查看並管理您收藏的新聞文章，快速找到過去感興趣的內容，隨時掌握重要資訊。',
    category: NewsCategory.RANKING_LIST,
    link: '/member/rankinglist',
    navs: [],
    isRoot: false,
    enabled: false
  },
  [NewsCategory.MY_STOCKS]: {
    name: '自選股',
    title: '自選股',
    keywords: [],
    description: '',
    category: NewsCategory.MY_STOCKS,
    navs: [],
    link: `${wwwChannelLink}/member/portfolios`,
    isRoot: true,
    enabled: true
  },
  [NewsCategory.ANNOUNCEMENT]: {
    name: '公告',
    title: '公告',
    description: '',
    category: NewsCategory.ANNOUNCEMENT,
    navs: [{ name: '全部', title: '公告', category: NewsCategory.ANNOUNCEMENT }],
    isRoot: true,
    enabled: false
  },
  [NewsCategory.ADVERTORIAL]: {
    name: '一手情報',
    title: '一手情報',
    description: '',
    category: NewsCategory.ANNOUNCEMENT,
    navs: [{ name: '全部', title: '一手情報', category: NewsCategory.ADVERTORIAL }],
    isRoot: true,
    enabled: false
  }
};

export const mobileNavigation = {
  ...navigation,
  [NewsCategory.HEADLINE]: {
    name: '即時頭條',
    title: '頭條新聞',
    keywords: ['新聞', '頭條'],
    description:
      '提供最即時的頭條新聞，包含國內外最新時事、外匯變動、股市波動變化等頭條新聞，鉅亨網帶您了解市場重要新聞。',
    category: NewsCategory.HEADLINE,
    navs: [
      { name: '首頁', title: '頭條新聞', category: NewsCategory.HEADLINE },
      { name: '全部', title: '頭條新聞', category: NewsCategory.HEADLINE_ALL },
      {
        name: '主編精選',
        title: '主編精選',
        category: NewsCategory.NEWS_24H,
        description:
          '提供今日要聞，快速掌握今日國內外重要新聞、國際市場走勢、重大財經訊息，前往Anue鉅亨了解今日重點新聞。'
      },
      {
        name: '熱門時事',
        title: '新聞-熱門時事',
        keywords: ['熱門時事', '熱門議題', '時事', '議題', '新聞', '頭條'],
        description:
          '查看最新具有話題的熱門時事，提供熱門時事相關資訊、介紹、以及歸納與時事相關的新聞，並帶出相關連的商品，快速掌握時事脈絡。',
        category: NewsCategory.POP_TOPIC,
        navs: [],
        link: '/poptopic',
        isRoot: true,
        enabled: true
      },
      { name: '議題', title: '議題', category: NewsCategory.TOP_TOPICS },
      { name: '宏觀', title: '宏觀', category: NewsCategory.NEWS_MARCO },
      { name: '人氣', title: '人氣', category: NewsCategory.TRENDING, link: `${newsChannelLink}/trending` },
      { name: '專題', title: '專題', category: NewsCategory.PROJECT, link: `${newsChannelLink}/projects/cat/all` },
      { name: '總覽', title: '總覽', category: NewsCategory.ALL }
    ],
    link: '',
    isRoot: true,
    enabled: true
  },
  [NewsCategory.CN_STOCK]: {
    name: '陸港股',
    title: '陸港股新聞',
    keywords: ['A股', '港股', '新聞', '滬深股', '上證'],
    description: '最新大陸股市新聞，包含A股港股最新訊息、大陸政治經濟情報、及市場評論分析。',
    category: NewsCategory.CN_STOCK,
    navs: [
      { name: '頭條', title: '陸港股頭條新聞', category: NewsCategory.CN_STOCK },
      { name: '全部', title: '陸港股新聞', category: NewsCategory.CN_STOCK_ALL },
      { name: '港股', title: '港股', category: NewsCategory.HK_STOCK },
      { name: 'A股', title: 'A股', category: NewsCategory.SH_STOCK },
      { name: '大行報告', title: '大行報告', category: NewsCategory.HK_REPORT },
      { name: '新股上市', title: '新股上市', category: NewsCategory.HK_IPO },
      { name: '權證', title: '權證', category: NewsCategory.HK_WARRANT },
      { name: '香港政經', title: '香港政經', category: NewsCategory.HK_MACRO },
      { name: '大陸政經', title: '大陸政經', category: NewsCategory.CN_MACRO }
    ],
    link: '',
    isRoot: true,
    enabled: true
  }
};

export type NewsNavigationTypes = keyof typeof navigation;

export default navigation;
