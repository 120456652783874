/* eslint-disable react-hooks/rules-of-hooks */
'use client';

import { isClient } from '@fe-common-utils/libs/utils';
import { useCallback, useLayoutEffect, useState } from 'react';

// jest 沒有 window.matchMedia 所以用來定義
if (isClient && !window.matchMedia) {
  Object.defineProperty(window, 'matchMedia', {
    writable: true,
    value: jest.fn().mockImplementation(query => ({
      matches: false,
      media: query,
      onchange: null,
      addEventListener: jest.fn(),
      removeEventListener: jest.fn(),
      dispatchEvent: jest.fn()
    }))
  });
}

const getMatches = (mediaQuery: string): boolean => isClient && window.matchMedia(mediaQuery).matches;
const useMediaQuery = (query: string, defaultValue?: boolean): boolean => {
  const [matches, setMatches] = useState<boolean>(defaultValue || getMatches(query));

  const onMediaQueryChange = useCallback((): void => {
    setMatches(getMatches(query));
  }, [query]);

  useLayoutEffect(() => {
    const mediaQuery = window.matchMedia(query);

    onMediaQueryChange();

    mediaQuery.addEventListener('change', onMediaQueryChange);

    return (): void => {
      mediaQuery.removeEventListener('change', onMediaQueryChange);
    };
  }, [onMediaQueryChange, query]);

  return matches;
};

export default useMediaQuery;
