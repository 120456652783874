import type { NewsNavigationTypes } from '@fe-news/configs/navigation';
import navigation, { mobileNavigation } from '@fe-news/configs/navigation';
import { NewsCategory } from '@fe-news/constants/categories';

export const DEFAULT_IS_HEADLINE_CATEGORIES = [
  NewsCategory.HEADLINE,
  NewsCategory.NEWS_24H,
  NewsCategory.TOP_TOPICS,
  NewsCategory.NEWS_MARCO,
  NewsCategory.TRENDING,
  NewsCategory.PROJECT,
  NewsCategory.ALL,
  NewsCategory.CN_STOCK,
  NewsCategory.WD_STOCK
];

/**
 * @returns {NewsCategory}
 * @description 取得預設分類 (即時新聞)
 */
export function getDefaultCategory(): NewsCategory {
  return NewsCategory.HEADLINE;
}

/**
 * @param category {NewsCategory}
 * @returns {NavigationItem}
 * @description 取得傳入類別的父層類別
 * 註: (加入 cache 手法以提升效能)
 */
let cachedCategory = '';
let cachedNavigationItem: MaybeUndefined<NavigationItem>;
export function getParentCategory(category: NewsCategory): MaybeUndefined<NavigationItem> {
  if (cachedCategory === category) return cachedNavigationItem;

  cachedCategory = category;
  cachedNavigationItem = Object.values(navigation).find(({ navs }) => {
    return navs.find(nav => nav.category === formatCategoryWithComma(category));
  });

  return cachedNavigationItem;
}

export function getCategory(category: NewsCategory): MaybeUndefined<NavigationItem> {
  return category
    ? navigation[formatCategoryWithComma(category) as NewsNavigationTypes]
    : navigation[NewsCategory.HEADLINE] || undefined;
}

export function getMobileParentCategory(category: NewsCategory): MaybeUndefined<NavigationItem> {
  return Object.values(mobileNavigation).find(({ navs }) => {
    return navs.find(nav => nav.category === formatCategoryWithComma(category));
  });
}

export function getMobileCategory(category: NewsCategory): MaybeUndefined<NavigationItem> {
  return mobileNavigation[formatCategoryWithComma(category) as NewsNavigationTypes] || undefined;
}

// 如果category包含',' us_live,us_forecast會變成us_live%2Cus_forecast
function formatCategoryWithComma(category: NewsCategory) {
  if (category.includes(encodeURIComponent(','))) {
    return decodeURIComponent(category) as NewsCategory;
  }

  return category;
}

export function getSubCategory(category: NewsCategory): MaybeUndefined<NavigationItem> {
  return (
    getParentCategory(category)?.navs?.find(nav => nav.category === formatCategoryWithComma(category)) || undefined
  );
}

/**
 * @param category {NewsCategories}
 * @returns {boolean}
 * @description 回傳分類是否為大分類(包含子分類的分類)
 */
export function getIsPrimaryCategory(category: NewsCategory) {
  return category in navigation;
}

/**
 * @param category {NewsCategory}
 * @returns {boolean}
 * @description 回傳傳入分類是否是合法分類
 */
export function getIsCategoryExists(category: NewsCategory) {
  return Object.values(NewsCategory).includes(formatCategoryWithComma(category));
}

/**
 * @param category {NewsCategory}
 * @returns {NavigationItem}
 * @description 取得傳入類別，可能是根類別也可能是子類別的 SEO 資訊
 */
export function getCategoryInfo(category: NewsCategory = NewsCategory.HEADLINE): MaybeUndefined<NavigationItem> {
  const isRootNode = getIsPrimaryCategory(category);

  if (isRootNode) {
    return getCategory(category);
  } else {
    const parentCategory = getParentCategory(category);
    const current = getSubCategory(category);

    // 將父層類別的 SEO 資訊覆蓋到子層類別
    return {
      ...current,
      name: String(current?.name || ''),
      category: String(current?.category || ''),
      keywords: current?.keywords || parentCategory?.keywords || [],
      title: String(current?.title || parentCategory?.title || ''),
      description: String(current?.description || parentCategory?.description || '')
    };
  }
}

/**
 * @param category {NewsCategory}
 * @returns {Array<{ id: string; name: string; link: string}>}
 * @description 只取得第一層主類別資訊，用於桌機新聞上方灰色分類選單
 */
export const mainRootForSubHeaderUse: { id: string; name: string; link: MaybeUndefined<string> }[] = Object.values(
  navigation
)
  .filter(({ enabled }: NavigationItem) => enabled !== false)
  .map(({ category, name, link }: NavigationItem) => {
    // 桌機不需要顯示"首頁"這個在手機上的特殊次分類，所以要過濾掉
    // const realLink = !link || link?.includes('home') ? `/news/cat/${category}` : link;

    return {
      id: category,
      name: category === NewsCategory.HEADLINE ? '頭條' : name,
      link: link || `/news/cat/${category}`
    };
  });

// 手機版分類導覽列
export const mobileNav = Object.values(navigation).filter(({ category }) =>
  [
    NewsCategory.HEADLINE,
    NewsCategory.TW_STOCK,
    NewsCategory.WD_STOCK,
    NewsCategory.TECH,
    NewsCategory.CN_STOCK,
    NewsCategory.POP_TOPIC,
    NewsCategory.ANUE_LIVE,
    NewsCategory.FUND,
    NewsCategory.FOREX,
    NewsCategory.CRYPTO,
    NewsCategory.HOUSE,
    NewsCategory.TW_MONEY,
    NewsCategory.FUTURES,
    NewsCategory.CELEBRITY_AREA,
    NewsCategory.MY_NEWS
  ].includes(category)
);

/**
 * @param pathname {string}
 * @returns {string}
 * @description 取得路由 `/news/cat/:category` 的 category 參數
 */
export const getCategoryFromPathname = (pathname: string): NewsCategory => {
  const path = pathname.split('/');

  const category =
    path[1] === 'poptopic'
      ? NewsCategory.POP_TOPIC
      : path[1] === 'member'
        ? NewsCategory.MY_NEWS
        : (path[3] as NewsCategory);

  return category || NewsCategory.HEADLINE;
};

/**
 * 新聞內頁是否顯示區塊鏈行銷文字(ANUE-8756)
 * 顯示規則 a or b
 * a: 新聞類別為 bc_news、bc_live，同時發文者ID=zz (isOutsource = 1) e.g. https://api.cnyes.com/media/api/v1/news/5202196?status=no_token
 * b: 新聞類別為 crypto_live e.g. https://api.cnyes.com/media/api/v1/news/5204370?status=no_token
 * @param breadcrumbs
 * @param isOutsource
 * @returns boolean
 */
export const isShowCryptoPromotionText = (
  breadcrumbs: {
    categoryId: number;
    slug: string;
    name: string;
  }[],
  isOutsource?: 0 | 1
) => {
  const slugs = breadcrumbs?.map(({ slug }) => slug) || [];

  if (slugs.includes(NewsCategory.CRYPTO_LIVE)) return true;
  if (isOutsource === 1 && (slugs.includes(NewsCategory.BC_NEWS) || slugs.includes(NewsCategory.BC_LIVE))) return true;

  return false;
};
