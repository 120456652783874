import { getStockCode, getStockMarket } from '../stock';

const env = process.env.NEXT_PUBLIC_ENVIRONMENT;
const isProd = env === 'prod';
const cnyesBaseUrl = isProd ? 'cnyes.com' : `${env}.cnyes.cool`;
const wwwChannelLink = `https://www.${cnyesBaseUrl}`;
const invest = `https://invest.${cnyesBaseUrl}`;
const fundChannelLink = `https://fund.${cnyesBaseUrl}`;
const cryptoChannelLink = `https://crypto.${cnyesBaseUrl}`;
const newsChannelLink = `https://news.${cnyesBaseUrl}`;
// const mobileNewsChannelLink = `https://m.${cnyesBaseUrl}`;
const apiBaseUrl = isProd ? 'cnyes.com' : `${env}.cnyes.cool`;

const LinkFactory = {
  env,
  invest,
  fundChannelLink,
  cryptoChannelLink,
  newsChannelLink,
  // mobileNewsChannelLink,
  apiBaseUrl,
  anueHaoLink: 'https://hao.cnyes.com',
  cookieDomain: isProd ? '.cnyes.com' : '.cnyes.cool',
  cnyesBaseUrl: isProd ? 'cnyes.com' : `${env}.cnyes.cool`,
  wwwChannelLink: `https://www.${cnyesBaseUrl}`,
  forexChannelLink: `${wwwChannelLink}/forex/reuters`,
  forexCrossListLink: `${wwwChannelLink}/forex/crosslist`,
  mobileNewsCategoryChannelLink: `${newsChannelLink}/news/cat`,
  mobileNewsDetailLink: `${newsChannelLink}/news/cat`,
  forexDetailPageLink: `${invest}/forex/detail`,
  driverFundDetailLink: `${invest}/funds/detail`,
  stockTalk: `https://stock.${cnyesBaseUrl}`,
  globalStockTalk: `https://global-stock.${cnyesBaseUrl}`,
  desktopFundsSearch: `${fundChannelLink}/search`,
  mobileFundsSearch: `${invest}/funds/search`,
  ampNewsUrl: `https://amp-news.${cnyesBaseUrl}`,
  loginLink: `https://login.${cnyesBaseUrl}`,
  feedbackLink: 'https://docs.google.com/forms/d/1QvhPSjjG-vrxPUvpzGd2uYmFiIs1cMu1luFAwSonm6U/viewform',
  feedbackEntryUrlId: '1831304503',
  cryptoChannelBTCLink: `${cryptoChannelLink}/BTC/24h`,
  newsCategoryChannelLink: `${newsChannelLink}/news/cat`,
  wdStockNewsLink: `${newsChannelLink}/news/cat/wd_stock`,
  forexNewsLink: `${newsChannelLink}/news/cat/forex`,
  cryptoNewsLink: `${newsChannelLink}/news/cat/bc_virtual`,
  bcNewsLink: `${newsChannelLink}/news/cat/bc`,
  fundNewsLink: `${newsChannelLink}/news/cat/fund`,
  twStockNewsLink: `${newsChannelLink}/news/cat/tw_stock`,
  usStockNewsLink: `${newsChannelLink}/news/cat/us_stock`,
  cnStockNewsLink: `${newsChannelLink}/news/cat/cn_stock`,
  topTopicsNewsLink: `${newsChannelLink}/news/cat/topTopics`,
  magNewsLink: `${newsChannelLink}/news/cat/mag`,
  projectNewsLink: `${newsChannelLink}/projects/cat/all`,
  forexIndexLink: 'https://forex.cnyes.com',
  driverPageLink: `${invest}/drivers`,
  driverSearchLink: `${invest}/drivers/search`,
  newsTagLink: `${newsChannelLink}/tag`,
  newsDetailLink: `${newsChannelLink}/news/id`,
  hotTagsApiUrl: `https://api.${apiBaseUrl}/api/v1/news/recommend/tags`,
  hotDriversApiUrl: `https://driver.api.${apiBaseUrl}/driver/api/v1/drivers?order=1y&sort=desc&page=1&size=3`,
  hotTradesApiUrl: `https://ws.api.${apiBaseUrl}/ws/api/v1/ga/pageViewHot`,
  anueSearchAllCategory: 'TW,FUND,US,CC,INDEX,FUTURE,ETF,FX,EOD,CHK',
  anueSearchAllApiUrl: `https://ess.api.${apiBaseUrl}`,
  anueSearchTWApiUrl: `https://ess.api.${apiBaseUrl}/ess/api/v1/siteSearch/main?category=TW&limit=5&q=`,
  logoWallApiUrl: `https://api.${apiBaseUrl}/media/api/v1/logowalls/`,
  cosvcApiUrl: `https://cosvc.internalapi.${apiBaseUrl}/cosvc/api/v1`,
  archiveStockQLink: 'https://money.cnyes.com/archive/StockQ.aspx',
  wdStockChannelLink: 'https://www.cnyes.com/global/IndexImmediateQuotedPrice/',
  archiveUSStock: 'https://www.cnyes.com/archive/usastock/',
  archiveTWStock: 'https://www.cnyes.com/archive/twstock',
  archiveTWGStock: 'https://www.cnyes.com/archive/presh',
  newsAppDownloadLink: 'https://www.cnyes.com/mobile/index_app.htm',
  memberPortfoliosLink: `${wwwChannelLink}/member/portfolios`,
  popTopicDetailLink: `${newsChannelLink}/poptopic`
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function toUrlParams(params: any) {
  return Object.keys(params)
    ?.filter(key => !!params[key])
    ?.map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    ?.join('&');
}

/**
 * https://cnyesrd.atlassian.net/wiki/spaces/PS/pages/637993057
 * symbol = [market]:[xxx]:[mType]
 * objectType: 主要用來區分是否為基金商品 *exception: /ga/pageViewHot 回覆在 market 欄位而非 objectType
 * type: 無法從 symbol 區分出來的細分類, 如 ETF
 */
export const generateStockLinkBySymbol = (symbol: string, type?: string) => {
  const symbolSplit = String(symbol)?.split(':');

  if (!symbolSplit || symbolSplit?.length < 2) {
    return '';
  }

  // GF：symbol：FUTURES, TWF：symbol：FUTURES
  if ((symbolSplit[0] === 'TWF' || symbolSplit[0] === 'GF') && symbolSplit[2] === 'FUTURES') {
    return `${LinkFactory.invest}/futures/${symbolSplit[0]}/${symbolSplit[1]}`;
  }

  // GI：symbol：INDEX, TWS：symbol：INDEX
  if ((symbolSplit[0] === 'TWS' || symbolSplit[0] === 'GI') && symbolSplit[2] === 'INDEX') {
    return `${LinkFactory.invest}/index/${symbolSplit[0]}/${symbolSplit[1]}`;
  }

  if ((symbolSplit[0] === 'TWS' || symbolSplit[0] === 'TWG') && symbolSplit[2] === 'STOCK') {
    return `${LinkFactory.wwwChannelLink}/twstock/${symbolSplit[1]}`;
  }

  // 2022-12-28: 關閉舊的陸港股連結，啟用新的網址路由
  // if ((symbolSplit[0] === 'CNS' || symbolSplit[0] === 'HKS') && symbolSplit[2] === 'STOCK') {
  //   return `${globalStockTalk}/market/${symbol}`;
  // }
  if (symbolSplit[0] === 'CNS' && symbolSplit[2] === 'STOCK') {
    return `${LinkFactory.wwwChannelLink}/astock/quote/${symbolSplit[1]}`;
  }
  if (symbolSplit[0] === 'HKS' && symbolSplit[2] === 'STOCK') {
    return `${LinkFactory.wwwChannelLink}/hkstock/quote/${symbolSplit[1]}`;
  }

  // USS：symbol：STOCK，type：ETF
  // [ANUE-5745] 美股ETF內頁資訊較美股少，ETF/US直接改都去美股內頁usstock
  // e.g. /ETF/US/detail/QQQ to /usstock/detail/QQQ
  if (symbolSplit[0] === 'USS' && symbolSplit[2] === 'STOCK') {
    if (type && type === 'ETF') {
      return `${LinkFactory.invest}/usstock/detail/${symbolSplit[1]}`;
    }

    return `${LinkFactory.invest}/usstock/detail/${symbolSplit[1]}`;
  }

  if (symbolSplit[0] === 'FX') {
    return `${LinkFactory.forexDetailPageLink}/${symbolSplit[1]}/overview`;
  }

  if (symbolSplit[0] === 'EOD') {
    return `${LinkFactory.invest}/eod/detail/${symbolSplit[1]}`;
  }

  if (symbolSplit[0] === 'CC' && symbolSplit[1]) {
    const crypto = symbolSplit[1];

    return `${LinkFactory.cryptoChannelLink}/${crypto}`;
  }

  return '';
};

export const generateLinkBySymbol = (symbol: string) => {
  const market = getStockMarket(symbol);
  const code = getStockCode(symbol);

  switch (market) {
    case 'USS':
      return `${invest}/usstock/detail/${code}`;
    case 'HKS':
      return generateStockLinkBySymbol(symbol);
    case 'CNS':
      return generateStockLinkBySymbol(symbol);
    default:
      return `${wwwChannelLink}/twstock/${code}`;
  }
};

export const generateLink = (url = '') => {
  const domain = cnyesBaseUrl;

  return url.replace('{{host}}', domain);
};

export const generateFeedbackLink = (url = '') => {
  let entryUrl = url;

  if (!url && typeof window !== 'undefined' && window.location) {
    entryUrl = window.location.href;
  }

  return `${LinkFactory.feedbackLink}?usp=pp_url&entry.${LinkFactory.feedbackEntryUrlId}=${encodeURIComponent(
    entryUrl
  )}`;
};

export const generateFundSaleLink = ({
  forSale,
  cnyesId,
  source,
  medium,
  campaign
}: {
  forSale: number;
  cnyesId: string;
  source?: string;
  medium?: string;
  campaign?: string;
}) => {
  const paramList: string[] = [];
  const params = {
    utm_source: source,
    utm_medium: medium,
    utm_campaign: campaign
  };

  (Object.keys(params) as Array<keyof typeof params>).forEach(p => {
    if (params[p]) {
      paramList.push(`${p}=${params[p]}`);
    }
  });

  if (forSale === 1) {
    let url = `${fundChannelLink}/order/${cnyesId}`;

    if (paramList.length > 0) {
      url += `?${paramList.join('&')}`;
    }

    return url;
  }

  return '';
};

export const generateFundLink = (displayName: string, cnyesId: string) => {
  const fundName = `${displayName}`.replace(/<(?:.|\n)*?>/gm, '');

  return `${LinkFactory.driverFundDetailLink}/${encodeURIComponent(fundName)}/${cnyesId}`;
};

export const generateSearchMoreLink = ({ title, keyword }: { title: string; keyword: string }) => {
  const params = { keyword };
  const type = title === '行情' ? 'quote' : 'news';

  return `${LinkFactory.wwwChannelLink}/search/${type}?${toUrlParams(params)}`;
};

export const generateSearchResultLink = ({ keyword, backLink }: { keyword: string; backLink?: string }) => {
  let params: { keyword: string; aink?: string } = { keyword };

  if (backLink) {
    params = { ...params, aink: backLink };
  }

  return `${LinkFactory.wwwChannelLink}/search/all?${toUrlParams(params)}`;
};

export default LinkFactory;
