/**
 * 釋放廣告空間
 * @param slotInstance
 */
export const unmountSlots = (slotInstance?: unknown | null) => {
  const googletag = window.googletag || { cmd: [] };

  if (googletag) {
    if (slotInstance) {
      googletag.cmd?.push(() => {
        googletag.destroySlots([slotInstance]);
      });
    }
  }
};

/**
 * 重新載入廣告
 * e.g.當resize時
 */
export const refresh = (slotInstance?: unknown | null) => {
  const googletag = window.googletag || null;

  if (googletag?.pubadsReady) {
    googletag.cmd = googletag.cmd || [];

    if (slotInstance) {
      googletag.cmd.push(() => {
        googletag.pubads().refresh([slotInstance]);
      });
    }
  }
};
